import { ReactSVG } from "react-svg";
import arrowRight from "../../assets/icon_arrow4_zinnoberrot.svg";
import arrowUp from "../../assets/icon_arrow_zinnoberrot.svg";
import arrowEtappen from "../../assets/icon_etappen_zinnoberrot.svg";
import arrowEtappenD from "../../assets/icon_etappen_zinnoberrot_d.svg";
import arrowLocation from "../../assets/icon_location_zinnoberrot.svg";
import arrowLocationD from "../../assets/icon_location_zinnoberrot_d.svg";
import arrowTeamD from "../../assets/icon_team_zinnoberrot_d.svg";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="home-container">
      <div className="content-grid">
        <div className="content-left">
          <div className="content-left-title">
            <div className="content-left-maintitle">
              von<h1>WISMAR</h1>nach<h1>WIEN</h1>
            </div>
            <div className="content-left-subtitle">
              entlang der <b>DEMARKATIONSLINIE</b>
              <br />
              vom <b>Mai 1945</b>
            </div>
          </div>

          <div className="content-left-info">
            <div className="content-left-info-left">
              <ReactSVG src={arrowRight} />
              <span>ca. 1.400 km</span>
            </div>
            <div className="content-left-info-right">
              <ReactSVG src={arrowUp} />
              <span>ca. 9.500 hm</span>
            </div>
            <div className="content-left-info-left">
              <ReactSVG src={arrowEtappen} />
              <span>
                17 Etappen in 18 Tagen
                <br />
                zu den Vereinten Nationen
              </span>
            </div>
            <div className="content-left-info-right">
              <ReactSVG src={arrowLocation} />
              <span>80 Gedenkorte</span>
            </div>
          </div>
          <div className="content-left-text">
            <p>
              Am <b>27. Juni 2025</b> starten wir von der KinderUni Wismar
              unsere Fahrradtour zur UNO-City nach Wien anlässlich des Gedenkens
              an das Ende des Zweiten Weltkrieges und an die Gründung der
              Vereinten Nationen vor 80 Jahren.
            </p>
            <h3>Website-Launch am 27. März 2025</h3>
            <p>
              Hier findet ihr drei Monate vor dem Start alle Informationen zur
              Tour, den Gedenkorten und der Anmeldeoption für unsere erste
              Etappe.
            </p>
            <p>
              Um nichts zu verpassen, meldet euch bei unserem Newsletter an.
            </p>
          </div>
          <div className="content-left-button">
            <a href="#newsletter">
              <div className="icon">
                <ReactSVG src={arrowEtappenD} />
              </div>
              Zum Newsletter
            </a>
          </div>
        </div>
        <div className="content-right">
          <div className="content-right-top">
            <div className="content-right-image">
              <div className="content-right-image-circle meeting-line" />
            </div>
            <div className="content-right-content">
              <div className="content-right-content-title">
                <h2 style={{ textTransform: "uppercase " }}>
                  Was ist die Demarkationslinie?
                </h2>
              </div>
              <div className="content-right-content-text">
                <p>
                  An der <b>Demarkationslinie</b> – auch Elbe-Mulde-Linie
                  genannt – trafen im Mai 1945 die westlichen Alliierten auf die
                  Rote Armee. Am 8. Mai endete der Zweite Weltkrieg in Europa.
                </p>
                <p>
                  Der Handschlag von Torgau symbolisiert den Friedenswillen und
                  nur wenige Wochen später wurden die Vereinten Nationen
                  gegründet.
                </p>
              </div>
              <div className="content-right-content-button">
                <Link reloadDocument to={{ pathname: "/route" }}>
                  <div className="icon">
                    <ReactSVG src={arrowEtappenD} />
                  </div>
                  Zur Route
                </Link>
              </div>
            </div>
          </div>
          <div className="content-right-middle">
            <div className="content-right-image">
              <div className="content-right-image-circle mission" />
            </div>
            <div className="content-right-content">
              <div className="content-right-content-title">
                <h2 style={{ textTransform: "uppercase " }}>
                  Was ist das Ziel?
                </h2>
              </div>
              <div className="content-right-content-text">
                <p>
                  Mit unserer <b>Gedenk-Fahrradtour</b> entlang der
                  Demarkationslinie wollen wir an die Ereignisse vor mehr als 80
                  Jahren erinnern und ein Zeichen für Frieden in Europa und in
                  der Welt setzen.
                </p>
              </div>
              <div className="content-right-content-button">
                <Link reloadDocument to={{ pathname: "/mission" }}>
                  <div className="icon">
                    <ReactSVG src={arrowLocationD} />
                  </div>
                  Zur Mission
                </Link>
              </div>
            </div>
          </div>
          <div className="content-right-bottom">
            <div className="content-right-image">
              <div className="content-right-image-circle team" />
            </div>
            <div className="content-right-content">
              <div className="content-right-content-title">
                <h2>WER SIND WIR?</h2>
              </div>
              <div className="content-right-content-text">
                <p>
                  Wir sind fünf radsportbegeisterte Familienväter, die sich in
                  ihrer Freizeit für Geschichte interessieren.
                </p>
              </div>
              <div className="content-right-content-button">
                <Link reloadDocument to={{ pathname: "/team" }}>
                  <div className="icon">
                    <ReactSVG src={arrowTeamD} />
                  </div>
                  Zum Team
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
