import {
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import "./css/App.scss";
import "./css/fonts.scss";
import Header from "./components/Header/Header";
import Home from "./components/Content/Home";
import Footer from "./components/Footer/Footer";
import Partner from "./components/Footer/Partner";
import { useEffect, useState } from "react";
import Team from "./components/Content/Team";
import Vision from "./components/Content/Vision";
import Tour from "./components/Content/Tour";
import Impressum from "./components/Content/Impressum";
import DataSecurity from "./components/Content/DataSecurity";
import Sponsors from "./components/Content/Sponsors";
import Newsletter from "./components/Content/Newsletter";

function Main() {
  const location = useLocation();

  const [isHome, setIsHome] = useState(location.pathname === "/");

  useEffect(() => {
    if (location.pathname === "/" && !isHome) {
      setIsHome(true);
    } else if (location.pathname !== "/" && isHome) {
      setIsHome(false);
    }
  }, [isHome, location, setIsHome]);

  return (
    <div className="App">
      <header className="header">
        <Header />
      </header>
      <main className={`main${isHome ? " is-home" : ""}`}>
        <div className={`route-container${isHome ? " is-home" : ""}`} />
        <div className={`main-conainer`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mission" element={<Vision />} />
            <Route path="/route" element={<Tour />} />
            <Route path="/team" element={<Team />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<DataSecurity />} />
            <Route path="/partner" element={<Sponsors />} />
            <Route path="/newsletter" element={<Newsletter />} />
          </Routes>
        </div>
      </main>
      <footer id="newsletter" className="footer">
        <Footer />
      </footer>
      <Partner />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;
